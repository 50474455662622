<template>
    <b-container fluid>
        <div class="card card-primary">
            <div class="card-header">
                <h3 class="card-title">Filialbestellung: <template v-if="notEmptyObject(order)">{{ order.ordernumber }}</template></h3>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-md-6" style="display: flex; flex-direction: column;">
                        <div class="card card-primary card-outline" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                            <div class="card-header">
                                <h3 class="card-title">Filiale</h3>
                            </div>
                            <div class="card-body" v-if="notEmptyObject(order)">
                                {{ order.store.name }}<br>
                                {{ order.store.street }} {{ order.store.house_number }}<br>
                                {{ order.store.zipcode }} {{ order.store.city }}<br>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" style="display: flex; flex-direction: column;">
                        <div class="card card-primary card-outline" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                            <div class="card-header">
                                <h3 class="card-title">Allgemeine Infos</h3>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Infos</label><br>
                                    <span v-if="order.info != null">{{order.info}}</span>
                                    <span v-else>-- Keine Informationen hinterlegt --</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-success card-outline">
                            <div class="card-header">
                                <h2 class="card-title">Bestellte Artikel</h2>
                            </div>
                            <div class="card-body" style="max-height: 500px; overflow-y: auto;">
                                <table class="table table-striped text-center">
                                    <thead>
                                        <tr>
                                            <th>HAN</th>
                                            <th>Artikelnummer</th>
                                            <th>Artikelname</th>
                                            <th>Anzahl</th>
                                            <th>Kundenbestellungen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="article in order.items" :key="article.id">
                                            <td>{{ article.han }}</td>
                                            <td>{{ article.ordernumber }}</td>
                                            <td>{{ article.name }}</td>
                                            <td>{{ article.qty }}</td>
                                            <td>{{ article.customer_qty }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-md-12 text-right" v-if="order.status != null">
                        <router-link class="btn btn-secondary mr-1" :to="{name: 'store-orders.index'}">Zurück</router-link>
                        <button type="button" class="btn btn-danger" @click="downloadPicklist" :disabled="order.status.name == 'open'">Download PDF</button>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: "StoreOrdersShow",
    title: "Filialbestellung ansehen",

    data(){
        return {
            order: {},
        }
    },

    methods:{
        downloadPicklist()
        {
            this.axios
                .get("/store-orders/" + this.$route.params.id + "/download", {responseType: 'arraybuffer'})
                .then((response) => {
                    //this.orders = response.data.data;
                    this.downloadFile(response)
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        downloadFile(response){
        var newBlob = new Blob([response.data], {type: 'application/pdf'})

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        var link = document.createElement('a')
        link.href = data
        link.download = 'Bestellschein-' + this.order.ordernumber + '.pdf'
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
        }, 100)
    },

        loadSupplierOrder(){
            this.$Progress.start();
            this.axios
                .get("/store-orders/" + this.$route.params.id)
                .then((response) => {
                    this.order = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

    },

    created(){
        this.loadSupplierOrder();
    }

}
</script>

<style>

</style>